import * as React from "react";

import { PageLayout, SEO } from "@components/page-layout";
import { Box } from "@components/box";
import {
  GrayPhrase,
  Paragraph,
  PageHeading,
  Heading,
} from "@components/typography";
import { Stories } from "@containers/stories";
import { ICashStories } from "@containers/icash-stories";

const ICashStoriesPage = () => (
  <PageLayout>
    <Box className="sm:px-[22px]">
    <SEO title="#icashstories" />
    <Box className="text-center max-w-[660px] mx-auto mt-[120px] lg:mt-[100px] md:text-left">
      <PageHeading>
        <GrayPhrase>#</GrayPhrase>icashstories
      </PageHeading>
    </Box>
    <Paragraph className="max-w-[660px] mt-[22px] md:mb-[7px] sm:mb-[0px] mx-auto text-center md:text-left">
      <GrayPhrase>
        Curabitur quam erat, sollicitudin et congue quis, elementum eu sapien.
        Pellentesque ut massa nec nunc sagittis condimentum eu at arcu.
      </GrayPhrase>
    </Paragraph>
    <ICashStories>
      <div className="flex flex-col">
        <label className="global--label">Tamara - mother of two kids John and Kobo</label>
        <label className="global--label">Toronto. City in Ontario</label>
      </div>
      <div className="my-[33px]">
        <h3 className="global--subheading">When Banks Say No.</h3>
        <h3 className="global--subheading">We Say Yes</h3>
      </div>
      <p className="global--paragraph">
        <span className="text-interface-300">
          #icash.ca helped us out immensely! We had our son last fall, he
          was a very welcomed surprise and I had just finished my
          professional standards, paid for my licensing and money was super
          tight. Plus I had worked for free a for a year for year or so
          before this, so we had debt that banks didn’t really want to look
          at. I returned to work, my husband took parental leave. The
          government was over a month getting us his first cheque and it was
          a huge struggle paying for everything with my first pay cheque
          back to work. #icash.ca really saved us!
        </span>
      </p>
    </ICashStories>
    <Box className="mx-auto max-w-[1320px] lg:max-w-[540px]">
      <Box className="mb-[22px]">
        <Heading>
          iCash <GrayPhrase>reviews</GrayPhrase>
        </Heading>
      </Box>
      <Box>
        <Box
          display="inline-block"
          lg={{ display: "block" }}
          className="max-w-[616px] mr-[44px] lg:mb-[1.5rem]"
        >
          <Paragraph>
            <GrayPhrase>
              When we launched our #iCASHSTORIES contest, we had no idea of the
              overwhelming response that it would generate so many iCASH Reviews
              and personal stories from fellow Canadians in such a short amount
              of time! We have been so completely touched and inspired reading
              some of the reviews and the many amazing stories that were sent
              in. We know that everyone gets into financial trouble now and then
              and it was important for us to let all of our clients know that
              they are not alone. We are proud of our clients that broke their
              silence and shared their story about how iCASH was there to help.
              We know that reading these stories will help every Canadian out
              there realize that we all have #iCASHSTORIES.
            </GrayPhrase>
          </Paragraph>
        </Box>
        <Box
          display="inline-block"
          lg={{ display: "block" }}
          className="max-w-[616px] align-top"
        >
          <Paragraph className="mb-[1.5rem]">
            <GrayPhrase>
              Sharing these stories on social media was wonderful but we also
              wanted to give these stories a more permanent home. Here, on our
              website, we have gathered a few stories that we thought would be
              able to help give our clients hope. It was hard to pick just a few
              from so many touching, thoughtful, humorous, and inspiring stories
              but we did our best!
            </GrayPhrase>
          </Paragraph>
          <Paragraph>
            <GrayPhrase>
              We hope that sharing these stories will remind you that you’ve
              always got a helping hand with iCASH. And that brighter days are
              always ahead.
            </GrayPhrase>
          </Paragraph>
        </Box>
      </Box>
    </Box>
    <Box className="mt-[128px] md:mt-[5.5rem] pb-[5px]">
      <Stories />
    </Box>
    </Box>
  </PageLayout>
);

export default ICashStoriesPage;
