import * as React from "react";

import { StoriesCaption, StoriesCard, StoriesHeader } from "@brainfinance/icash-component-library";
import { generateTitle } from "../../helpers";
import { STORIESDATA } from "./stories-data";
import { StoriesProps } from "./stories";
import { Box } from "@components/box";

export const Stories = (props: React.PropsWithChildren<StoriesProps>) => (
  <Box>
    <StoriesHeader description={props.description}>
      {generateTitle({
        title: props.title,
        gray: props.gray,
      })}
    </StoriesHeader>
    <div className="stories--card-container">
      {STORIESDATA.map((testimonial, ndx) => (
        <div key={ndx} className={`flex items-start mb-[2.75rem] ${ndx % 2 === 0 ? "" : "justify-end sm:justify-start"} sm:flex-wrap`}>
          <StoriesCard {...testimonial} />
        </div>
      ))}
    </div>
    <StoriesCaption>{props.children}</StoriesCaption>
  </Box>
);

Stories.defaultProps = {
  title: "Customer stories",
  gray: "stories",
  description:
    "Sed ac feugiat lectus, a maximus ante. Donec imperdiet risus id dolor eleifend, a auctor ipsum fringilla. Fusce mollis facilisis ligula, non porta odio tristique ut. Nullam eget nisi non justo hendrerit mattis.",
  children: (
    <>
      Like our other 200,000 members, were we there to help you? Let us know by sending us your story at{" "}
      <a className="global--link" href="mailto:stories@icash.ca">
        stories@icash.ca
      </a>
      .
    </>
  )
};
