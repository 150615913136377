import React from "react";
import { getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { ICashStoriesProps } from "./icash-stories";
import { StoriesBanner } from "@brainfinance/icash-component-library";
// images
import defaultImg from "@images/default.png";

export const ICashStories = (props:React.PropsWithChildren<ICashStoriesProps>) => {
  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativeDirectory: { eq: "testimonials" } }, sort: { order: ASC, fields: name }) {
        nodes {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP], height: 775, quality: 70)
          }
        }
      }
    }
  `);
  const groupImg = allFile.nodes.map((storyImg: any) => (getImage(storyImg)?.images.fallback?.srcSet));
  return (
    <StoriesBanner src={props.src} groupSrc={props.groupSrc || groupImg}>
      {props.children}
    </StoriesBanner>
)
};

ICashStories.defaultProps = {
  src: defaultImg
}