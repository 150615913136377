import testimonial1 from "@images/testimonials/testimonial-1.png";
import testimonial2 from "@images/testimonials/testimonial-2.png";
import testimonial3 from "@images/testimonials/testimonial-3.png";
import testimonial4 from "@images/testimonials/testimonial-4.png";
import testimonial5 from "@images/testimonials/testimonial-5.png";

export const STORIESDATA = [
  {
    children: "#iCASHSTORIES!! Ok so Ive been through alot this past year and it has taken a toll on not only me but my entire family. I sadly suffered from a major addiction problem which obviously created a different side of me that i never want to see again. It took me a long time and for me to reach rock bottom before i was smart enough to seek the help that i needed. After i finished treatment successfully although i was clean and sober i was now in financial trouble and if it was not for icash helping me i dread to think where i would have ended up. Probably knocking on old acquaintances doors and ended right back where i started or even worse. So from the bottom of my heart i thankyou deeply you definaltly helped in starting my life over again the right way and im proud to say i have continued my sobriety and am 16months 3weeks and 5days.",
    city: "Toronto",
    province: "Ontario",
    description: "mother of two kids John and Kobo",
    name: "Tamara",
    title: "Real People. Real Heart",
    src: testimonial1,
  },
  {
    children: "During a family emergency, I was strapped for cash, tried every Avenue available, every time I applied got turned down, not knowing what to do a friend recommend icash. To my surprise and thankfulness icash was there for me and still is my number one recommendation, again thank you icash.",
    city: "Winnipeg",
    province: "Manitoba",
    description: "student",
    name: "Sonia",
    title: "Family Emergency",
    src: testimonial2,
  },
  {
    children: "I am a 63yr old single disabled gentleman who has had to rely on icash a few times as the low income I get isn't always stretchable enough to include food. I used to weight 180lbs then went to 140 for quite a few years but now I am at a scary 105 and thanks to icash, so far, I'm keeping at that for now with hopes of it going up.",
    city: "Edmonton",
    province: "Alberta",
    description: "student",
    name: "John",
    title: "Health Recovery",
    src: testimonial3,
  },
  {
    children: "iCASH helped me to get to the province I lived in so that I could pay my respects to my amazing mother who passed away. I would not have forgiven myself if I couldn’t be there to say our last goodbye",
    city: "Winnipeg",
    province: "Manitoba",
    description: "music teacher",
    name: "Rehanita",
    title: "Unexpected Life Event",
    src: testimonial4,
  },
  {
    children: "#iCASHSTORIES!! Ok so Ive been through alot this past year and it has taken a toll on not only me but my entire family. I sadly suffered from a major addiction problem which obviously created a different side of me that i never want to see again. It took me a long time and for me to reach rock bottom before i was smart enough to seek the help that i needed. After i finished treatment successfully although i was clean and sober i was now in financial trouble and if it was not for icash helping me i dread to think where i would have ended up. Probably knocking on old acquaintances doors and ended right back where i started or even worse. So from the bottom of my heart i thankyou deeply you definaltly helped in starting my life over again the right way and im proud to say i have continued my sobriety and am 16months 3weeks and 5days.",
    city: "Winnipeg",
    province: "Manitoba",
    description: "",
    name: "Ranol and Metron",
    title: "Pet care",
    src: testimonial5,
  },
  {
    children: "During a family emergency, I was strapped for cash, tried every Avenue available, every time I applied got turned down, not knowing what to do a friend recommend icash. To my surprise and thankfulness icash was there for me and still is my number one recommendation, again thank you icash.",
    city: "Toronto",
    province: "Ontario",
    description: "music student",
    name: "Nicolina",
    title: "Car repare",
    src: testimonial1,
  },
];
